import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../styles/themes/vad/borderRadius';
import { desktopMedia, pxToRem } from '../../../../styles/themes/vad/constants';
import { fontSize } from '../../../../styles/themes/vad/font';

const useStyles = makeStyles(theme => {
  const { left, borderLeft, marginLeft, paddingLeft } = theme.mixins;

  return {
    heading: {
      width: pxToRem(250),
      [theme.breakpoints.up('lg')]: {
        width: pxToRem(500),
      },
    },
    headingV2: {
      width: '90%',
      ...marginLeft(0),
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '50%',
        maxWidth: '653px',
      },

      '& h2': {
        ...theme.palette.common.ellipsis(3),
      },
    },
    exploreButton: {
      marginTop: pxToRem(16),
    },
    wrapper: {
      position: 'relative',
      flexDirection: 'column',
      marginTop: pxToRem(38),
      marginBottom: pxToRem(56),
      [desktopMedia]: {
        marginTop: pxToRem(72.62),
        marginBottom: pxToRem(88),
      }, '& .tabs-with-collage-heading h2, & .card-title': {
        'html[dir="rtl"] &': {
          paddingBottom: '6px',
        },
      },
      '& .tabs-with-collage-heading': {
        [theme.breakpoints.up('lg')]: {
          maxWidth: 'calc(50vw - 80px)',
        },
      },
      '& .MuiTabs-root': {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
      },

      '& .collage-tabs': {
        marginTop: pxToRem(49),
        marginBottom: pxToRem(32),
      },

      '& .pills-tabs-container': {
        width: '100%',
        backgroundColor: 'transparent',
        [theme.breakpoints.down('md')]: {
          width: `calc(100% + ${pxToRem(24)})`,
        },
        '& .tabs-desktop-view': {
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        },

        [theme.breakpoints.up('lg')]: {
          '& .tabPanelContainer': {
            display: 'flex',
          },
        },
        '& .MuiTab-root': {
          [theme.breakpoints.down('md')]: {
            ...theme.mixins.marginRight(pxToRem(28)),
            ...theme.mixins.paddingLeft(pxToRem(12)),
            fontWeight: 500,
          },
        },
      },
    },
    root: {
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
      },
    },
    cardWrapper: {
      border: 0,
      borderRadius: borderRadius.b0,
      background: 'transparent',
      [theme.breakpoints.down('md')]: {
        height: '100%',
        display: 'block',
        borderBottom: 0,
      },

      '& a': {
        textDecoration: 'none',
        color: 'inherit',
      },

      '& .card-content': {
        [theme.breakpoints.down('md')]: {
          padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
          ...paddingLeft(0),
          borderBottom: 0,
        },
        [theme.breakpoints.up('lg')]: {
          padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
        },
        '& p': {
          display: 'flex',
          color: theme.palette.text.tertiary,
        },
      },
      '& .card-title': {
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
          overflow: 'hidden',
        },
      },
    },

    cardsContainer: {
      justifyContent: 'space-between',
      marginTop: `calc(var(--collageTitleTextHeight) * -1 - 4rem - ${pxToRem(40)})`,
    },

    cardWrapperV2: {
      border: 0,
      borderRadius: 0,
      background: 'transparent',
      [theme.breakpoints.down('md')]: {
        height: '100%',
        display: 'block',
        border: 0,
      },

      '& a': {
        textDecoration: 'none',
        color: 'inherit',
      },

      '& .card-content': {
        borderBottom: 0,
        padding: `${theme.spacing(2)} 0}`,
        [theme.breakpoints.down('md')]: {
          padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
          ...paddingLeft(0),
          borderBottom: 0,
        },

      },
      '& .card-title': {
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
          overflow: 'hidden',
        },
      },
    },

    'col-left': {

      // color first 4 in unique color then repeat
      '& .card-wrapper:nth-child(1)': {
        color: theme.palette.primary.main,
      },
      '& .card-wrapper:nth-child(2)': {
        color: theme.palette.secondary.main,
      },
      [theme.breakpoints.up('lg')]: {
        alignSelf: 'flex-end',
        marginTop: `calc(var(--collageTitleTextHeight) + 4rem + ${pxToRem(40)})`,
        '& .img-to-animate-wrapper, & .video-container': {
          height: 'calc(602px - var(--collageTitleTextHeight))',
        },
      },
    },

    'col-right': {
      [theme.breakpoints.up('lg')]: {
        position: 'relative',
        right: '0px',
        top: '0px',
        height: '100%',
      },
    },

    'card-right': {
      [theme.breakpoints.up('lg')]: {
        position: 'relative',
        height: '100%',
        width: '90%',
        ...theme.mixins.marginLeft('auto'),
      },
      '& .card-media': {
        '& .img-to-animate-wrapper, & .video-container': {
          [theme.breakpoints.up('lg')]: {
            height: '722.24px',
          },
          '@media (min-width: 1024px) and (max-width: 1439px)': {
            height: '690px',
          },
        },
      },
    },
    'card-left': {
      '& .card-content': {
        [theme.breakpoints.up('lg')]: {
          display: 'flex',
          borderBottom: 0,
        },
      },
    },

    'card-0': {
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        ...theme.mixins.paddingRight(theme.spacing(2)),
      },
    },
    row1: {
      ...theme.mixins.paddingRight(theme.spacing(3)),
    },
    row2: {
      position: 'relative',
      top: 0,
      ...left('59.5%'),
      height: '100%',
      marginTop: '-15%',

      [theme.breakpoints.up('lg')]: {
        top: 'inherit',
        ...left('inherit'),
      },
      [theme.breakpoints.up('xxxl')]: {
        marginTop: '-12%',
      },
    },

    'card-1': {
      position: 'relative',
      [theme.breakpoints.up('lg')]: {
        ...theme.mixins.left('-25%'),
        width: 'calc(100% + 25%)',
      },
      '& .card-title': {
        marginBottom: theme.spacing(1),
      },
      '& .card-media': {
        [theme.breakpoints.up('lg')]: {
          ...borderLeft(theme.borders.collageBorder),
          borderBottom: theme.borders.collageBorder,
          backgroundColor: theme.mixins.White(),
        },
      },
      '& .card-content': {
        marginBottom: 0,

        [theme.breakpoints.up('lg')]: {
          ...marginLeft('20.5%'),
          marginTop: `-${theme.spacing(5)}`,
          marginBottom: theme.spacing(5),
        },
      },
    },
    'card-2': {
      '& .card-content': {
        [theme.breakpoints.up('lg')]: {
          padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
          paddingBottom: `${theme.spacing(4)}`,
        },
      },
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
      ...theme.mixins.marginRight('0 !important'),
      ...theme.mixins.marginLeft('-1.5rem !important'),

      '& .item': {
        flex: '0 0 auto',
        padding: pxToRem(16),
        paddingBottom: '0 !important',

        height: 'auto',

        '& .card-link-wrapper': {
          width: '100%',
        },

        [theme.breakpoints.up('lg')]: {
          marginTop: pxToRem(20),
        },
        [theme.breakpoints.down('md')]: {
          width: '80.55vw',
        },
      },
    },
  };
});

export default useStyles;
