import React from 'react';
import PropTypes from 'prop-types';
import CardContent from '@material-ui/core/CardContent';
import clsx from 'clsx';

import useStyles from './style';

const CardContentMUI = ({ className, children, ...other }) => {
  const classes = useStyles(className);

  return (
    <CardContent className={clsx(classes.root, className)} {...other}>
      <div className={classes.contentLeftBar}></div>
      <div className={classes.contentTextArea}>
        {children}
      </div>
    </CardContent>
  );
};

CardContentMUI.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

CardContentMUI.defaultProps = {
  className: '',
};

export default CardContentMUI;
