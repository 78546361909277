import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '& .card-action-area': {
      '& .MuiCardActionArea-focusHighlight': {
        display: 'none',
      },
      '&:hover': {
        '& .card-title': {
          color: theme.palette.common.SeaPort,
        },

        [theme.breakpoints.up('sm')]: {
          '& :not(.img-to-animate-wrapper) img, & .img-to-animate-wrapper': {
            transform: 'scale3d(1.1, 1.1, 1.1)',
          },
        },
      },
    },
  },
}));

export default useStyles;
