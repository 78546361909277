import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  media: {
    overflow: 'hidden',

    // TODO: remove this hover effect after
    // creating common hover
    '&.img-hover': {
      '& img': {
        width: '100%',
        display: 'block',
        imageRendering: 'crisp-edges',
        imageRendering: '-webkit-optimize-contrast',
      },
      [theme.breakpoints.up('sm')]: {
        '& :not(.img-to-animate-wrapper) img, & .img-to-animate-wrapper': {
          transition: 'all 1s ease',
          '&:hover': {
            transform: 'scale3d(1.1, 1.1, 1.1)',
          },
        },
      },
    },

    '& .video-container': {
      position: 'relative',
      '& video': {
        background: 'black',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
      '@media (max-width: 1023px)': {
        paddingTop: '50%',
        '& video': {
          position: 'absolute',
          top: 0,
          left: 0,
        },
      },
    },
  },
}));

export default useStyles;
