import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';

import useStyles from './style';
import Link from '../../atoms/Link';

const CardMUI = ({
  className,
  children,
  square,
  link,
  elevation,
  ...other
}) => {
  const classes = useStyles(className);
  const renderChild = isEmpty(link) ? (
    children
  ) : (
      <Link link={link} className="card-link-wrapper">
        <CardActionArea
          component="div"
          disableRipple
          className="card-action-area"
        >
          {children}
        </CardActionArea>
      </Link>
    );

  return (
    <Card
      className={clsx(classes.root, className)}
      // square handles border radius for card
      square={square}
      elevation={elevation}
      {...other}
    >
      {renderChild}
    </Card>
  );
};

CardMUI.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  link: PropTypes.shape({
    value: PropTypes.object,
  }),
  square: PropTypes.bool,
  elevation: PropTypes.number,
};

CardMUI.defaultProps = {
  className: '',
  square: false,
  link: {},
  elevation: 0,
};

export default CardMUI;
