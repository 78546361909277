import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: `${pxToRem(8)} ${pxToRem(16)} ${pxToRem(27)}`,

    [theme.breakpoints.up('lg')]: {
      paddingTop: pxToRem(24),
    },
  },
  contentLeftBar: {
    width: '10px',
    height: 'auto',
    ...theme.mixins.paddingLeft('10px'),
    background: theme.palette.common.SeaGreen,
  },
  contentTextArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    ...theme.mixins.paddingLeft(pxToRem(10)),

    '& p': {
      display: 'flex',
      textTransform: 'uppercase',
      color: theme.palette.common.UnselectedColor,
      ...theme.palette.common.ellipsis(2),
    },

    '& .card-title': {
      ...theme.palette.common.ellipsis(2),
    },
  },
}));

export default useStyles;
