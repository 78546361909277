import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CardMedia from '@material-ui/core/CardMedia';

import Picture from '../Picture';
import useStyles from './style';

const CardMediaMUI = ({
  className,
  image,
  mobileImage,
  scaleOnHover,
  aspectRatio,
  alternateAltText,
  preferredSize = null,
  videoDesktop,
  videoMobile,
}) => {
  const classes = useStyles(className);
  const imgHoverClass = scaleOnHover ? 'img-hover' : '';
  const cardMediaRef = useRef(null);

  let videoDesktopSrc = '';
  let videoMobileSrc = '';

  if (videoDesktop && videoDesktop?.value) {
    let videoDesktopValue = videoDesktop.value;
    videoDesktopSrc = videoDesktopValue?.src || videoDesktopValue?.url || videoDesktopValue?.href;
  }

  if (videoMobile && videoMobile?.value) {
    let videoMobileValue = videoMobile.value;
    videoMobileSrc = videoMobileValue?.src || videoMobileValue?.url || videoMobileValue?.href;
  }

  useEffect(() => {
    // Play video inside cardMediaRef if not playing
    const playVideo = () => {
      if (cardMediaRef.current && cardMediaRef.current.querySelector('video')) {
        const video = cardMediaRef.current.querySelector('video');
        // check if video loaded
        if (video && video.paused || video.readyState !== 4) {
          video.play();
          setTimeout(playVideo, 250);
        }
      }
    };
    playVideo();
  }, []);

  return (
    <CardMedia
      className={clsx(classes.media, imgHoverClass, className)}
      ref={cardMediaRef}
    >
      {
        /*
          If video is available use video (looped without controls) otherwise Image.
          Change video to Desktop or Mobile based on screen size.
        */
        videoDesktopSrc || videoMobileSrc ? (
          <div className='video-container'>
            <video
              controls={false}
              autoPlay
              loop
              muted
              playsInline
              className={clsx(classes.video, 'carousel-item-video')}
            >
              <source
                src={videoDesktopSrc || videoMobileSrc}
                media="(min-width: 768px)"
              />
              <source
                src={videoMobileSrc || videoDesktopSrc}
                media="(max-width: 767px)"
              />
            </video>
          </div>
        ) : (
          <Picture
            media={{
              image,
              mobileImage,
              aspectRatio,
              preferredSize,
            }}
            isParalax={true}
            disableLazyLoad={true}
            fallbackBackgroundNeeded={false}
            alternateAltText={alternateAltText}
          />
        )
      }
    </CardMedia>
  );
};

CardMediaMUI.propTypes = {
  className: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }).isRequired,
  scaleOnHover: PropTypes.bool,
  alternateAltText: PropTypes.string,
  aspectRatio: PropTypes.shape({
    mobile: PropTypes.string,
    desktop: PropTypes.string,
  }),
};

CardMediaMUI.defaultProps = {
  className: '',
  scaleOnHover: true,
  aspectRatio: {},
};

export default CardMediaMUI;
